
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');


.dndnode{
    background-color: white;
    border: 1px solid #FFFFFF;
    cursor: grab;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    margin: auto;
}

.dndnode:hover,
.dndnode:focus { border: 1px solid #444444; }

.dndnode span{
    color: #777777;
    vertical-align: middle;
}

.dndnode:hover span { color: #444444; }

.one-node{
    display: inline-block;
    text-align: center;
    width: 90px;
    justify-content: center;
    padding:10px;
    position: relative;
    top: 0;
}


.node-icon-text {
    color: #444444;
    font-family: 'Courier New';
    font-weight: bolder;
    font-size: .92rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}
.nodeTitle { font-size: 11px; vertical-align:middle; display: 
    inline-block;
padding: 1px;}

.search-bar{
    width: 270px;
    height: 44px;
}
.dndnode.input {
    border-color: #0041d0;
  }

  hr{
    width: 252px;
    height: 0px;

  }

  .nodeTitle {
    text-align: center;
  }
  
.panel-class{
    text-align: left;
    font-size: 16px;
    padding-left: 8px;
}

.panel-class .ant-collapse-content-box {
    padding: 0 5px 15px 5px !important;
}
  