.signup-page { margin-top: 100px; }
.brand-logo{ height: 40px; vertical-align: middle; padding-right:15px}
.signup-image { text-align: center; }
.g-image{
    height:20px;
    vertical-align:middle;
}
.signup-box{
    background: #F6F6F6;
    padding: 25px;
    border-radius: 10px;
    padding-top: 35px;
}
.brand-name { font-size: 32px; vertical-align: middle; font-weight: 600; }
.google-btn{
    font-size: 21px;
    background: #FFFFFF;
    padding: 10px 20px;
    height: auto;
    border: 1px solid #888888;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
}

.g-btn-wrapper{
    margin: 50px 0;
    text-align: center;
}


.or-line{
width: 50px;
height: 0.4px;
background: #4D4949;
box-shadow: 0px 4px 98px rgba(0, 0, 0, 0.25);
display: inline-block;
}

.or{
    margin-top: 25px;
    margin-bottom: 25px;
}
.acc{
    margin-top: 60px;
}
.form-input{
    width:404px;
    height:38.21px;
    border:1px solid #333333;
    border-radius:0px;
    margin-top:9px;
}
.signin-btn{
    width:404px;
    height:38.21px;
    margin-top:17px;
    border:1px solid #333333;
    border-radius:0px;
    background-color:#333333;
    color:white;
    font-size:18px
}
.signin-btn:hover{
    background-color: white;
}

.login-hero { text-align: center; }
.login-hero p { padding: 0; margin: 0; }
  


.login-hero .ribbon {
    font-size: 39px;
    font-weight: 500;
    color: #000000;
    display: inline-block;
    --r: .8em; /* control the ribbon shape */
    padding-inline: calc(var(--r) + .3em);
    line-height: 1.8;
    clip-path: polygon(0 0,100% 0,calc(100% - var(--r)) 50%,100% 100%,0 100%, var(--r) 50%);
    background: #F8CB46; /* the main color */
    width: fit-content;
  }


