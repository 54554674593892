@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.onboarding-image {
    margin-top: 100px;
    text-align: center;
    height: 100%;
}

.onboarding-image img {
    height: 100%;

}

.onboarding-box {
    box-sizing: border-box;
    /* background: #fff; */
    /* border: 2px solid #EAEAEA; */
    /* border-radius: 5px; */
    /* text-align: left; */
    padding: 30px 25px 80px 25px;
    margin-top: 50px;
    width: 500px;
}

.formLabel {
    font-size: 22px;
}