.brand-color { color: #F8CB46; }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #302834;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-drawer { margin-top: 64px; }
.error-message .ant-message-notice-content { background-color: #ffe1df !important; font-weight: 600; border: 1px solid #b6493f; border-radius: 5px !important; }
.success-message .ant-message-notice-content { background-color: #eaffd5 !important; font-weight: 600; border: 1px solid #7bb052; border-radius: 5px !important; }

.ant-layout { background: #FFF; }
.ant-layout-grey { background: #f5f5f5; }
.ant-layout-header {border-bottom: 1px solid #EAEAEA;}
.ant-layout-header .saveTimestamp { font-size: .8rem; font-weight: normal; margin-left: 20px; color: #666666; display: inline-block; vertical-align: middle; }
.ant-form-item-label label { font-size: 13px; font-weight: 600;}
.logoBar {background: #ffffff; border-bottom: 1px solid #EAEAEA; padding: 10px 10px 13px 10px; }

.bordered-table { border: 1px solid #f8f8f8; }

.form-field { margin-bottom: 20px; }
.form-field label { margin-bottom: 5px; display: block; font-size: 13px; font-weight: 500;}
.form-field label span { font-style: italic; color: #888888; font-size: 12px; margin-left: 10px; }
.form-field .ant-select {width: 100%;}

.ant-modal .ant-modal-content .ant-modal-header { padding-bottom: 20px; margin-bottom: 25px !important; }
.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title { font-size: 1.5rem; }
.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title svg { vertical-align: middle; margin-right: 15px; font-size: 26px; }
.ant-modal .ant-modal-content .ant-modal-footer { padding: 20px; background: #f4f4f4; border-top: 1px solid #EAEAEA; margin: 50px -24px -20px -24px; border-radius: 0 0 8px 8px;  }

.drawerForm .form-field { margin-bottom: 30px; }

.defaultMessage { background: #f2f1f1; padding: 15px; line-height: 1.5rem; border-radius: 10px; }
.checklistTimelines { margin: 40px 0 0 10px; }
.checklistTimelines h5 { margin-top: 0; }

.autoCompleteInput .anticon { position: absolute; z-index: 999; color: #666666; margin: 9px 0 0 -25px; }
.autoCompleteInput .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input { padding-right: 25px; }

.commentSection {display: flex; flex-direction: column; height: 100%;}
.commentSection .commentBox {margin-top: auto;}
.commentSection .commentBox .buttonWrapper { padding: 10px 0 25px 0; text-align: right;}
.comments .ant-list-item .ant-list-item-meta h4.ant-list-item-meta-title { margin: 0; margin-block-end: 0; margin-bottom: 0; font-size: 14px;}
.comments .ant-list-item .ant-list-item-meta .ant-list-item-meta-description { font-size: 12px; }
.comments .ant-list-item { padding: 25px 0; }

.comments .comment-metadata { font-size: 12px; }

.bot-recco p,
.bot-message-blurb p { background: #f4f4f4; padding: 10px; border-radius: 5px; }
.bot-avatar .ant-list-item-meta-avatar,
.bot-message-blurb .bot-avatar { background-color: #16b5fe; width: 32px; height: 33px; border-radius: 30px; text-align: center; padding-top: 6px;  }
.bot-avatar svg { font-size: 21px; vertical-align: middle;}
.bot-message-blurb .bot-avatar {padding: 0; display: inline-block;}
.bot-message-blurb .message { display: inline-block; margin: 0 0 0 15px; vertical-align: top; width: 85%; }
.bot-message-blurb .message h4 { padding: 0; margin: 0 0 10px 0; }


.form-button-bar { text-align: right; padding-top: 50px; }
.form-button-bar button { margin-left: 20px; }
.quill .ql-toolbar.ql-snow { border: 0; background: #f8f8f8; border-radius: 10px; margin-bottom: 20px; }
.quill .ql-toolbar.ql-snow button { height: 27px; margin-right: 15px; }
.ql-container.ql-snow { border: 0; font-size: 16px; }
.ql-container.ql-snow p,
.ql-container.ql-snow li { line-height: 30px; }

.brand-btn { vertical-align: middle; height: 40px; padding: 10px; }
.brand-btn span { vertical-align: top; display: inline-block; margin-right: 10px; font-weight: 600; }

.brand-btn.vanta { background: #a13dff; display: inline-block; }
.brand-btn.vanta:hover { background-color: #a13dff !important; border: 1px solid #300756 !important; }
.brand-btn.vanta span { color: #FFF; }
.brand-btn.vanta svg { height: 20px; }

.btn-more-rounded { border-radius: 100px; padding: 3px 2px 0 2px; vertical-align: middle; }
.btn-more-rounded svg { font-size: 24px; }

.btn-outlined { color: #1677ff; border-color: #1677ff; margin-right: 8px; }
.btn-outlined:hover { color: #0d4797 !important; border-color: #0d4797 !important; }

.btn-outlined.btn-default { color: #888888; border-color: #888888; }
.btn-outlined.btn-default:hover { color: #666666; border-color: #666666; }

.btn-icon-outlined { color: #1677ff; border-color: #1677ff; background: #FFFFFF; }
/* .btn-outlined.btn-icon-only span { font-size: 12px; vertical-align: middle; } */

.navbar-btn-wrapper { padding: 5px 40px 0 0; }
.navbar-btn-icon { border: 0; font-size: 24px; box-shadow: none; }

.btn-success { color: #FFFFFF; border-color: #119232; background: #1eba45;  }
.btn-success:hover { color: #FFFFFF; border-color: #119232; background: #169135 !important;  }


.ant-tag { font-size: 14px; padding: 3px 6px; }
.ant-tag.pill { border-radius: 20px; padding: 2px 8px !important; background: #FFF; border-color: #CDCDCD; }
.ant-tag.pill.green { color: #286b0b; background: #ebffd7; border-color: #55c525; }

.recommendedThreats { background: #faedff; padding: 15px 10px; border-radius: 5px; border: 1px solid #d159ff; }
.recommendedThreats h5 { margin: 0 0 10px 0; font-size: 18px; font-weight: 500; }
.recommendedThreats h6 { margin: 0 0 15px 0; font-size: 14px; font-weight: normal;}
.recommendedThreats .ant-collapse { background: #FFF;}
.dumb {background-color: #edf6f5;}

.bold-form { padding: 50px 25px 25px 0;  }
.bold-form .form-field { margin-bottom: 40px; }
.bold-form .form-field label { margin-bottom: 5px; display: block; font-size: 14px; font-weight: 500;}
.bold-form .form-field p { font-size: 16px; font-weight: 500; color: #282c34;}
.drawer-close-btn { font-size: 24px; vertical-align: middle; }
.drawer-close-btn span { vertical-align: top;}

.danger-modal .ant-modal-content { border: 2px solid #ff4d4f; }

.help-blurb { padding: 25px 15px; background: #FAE7FF; border: 1px solid #d6b9df; border-radius: 5px; margin-bottom: 20px; }
.help-blurb h3 { font-size: 18px; font-weight: 500; padding: 0; margin: 0 0 20px 0; }
.help-blurb h3 svg { vertical-align: middle; font-size: 24px; margin-right: 10px; color: #cb51f0; }
.help-blurb p { line-height: 21px; }

.help-blurb.warning { background: #ffefcb; border-color: #ff5112; }
.help-blurb.warning h3 svg {color: #ff5112;}

.integration-card { border: 1px solid #dddddd; border-radius: 5px; padding: 20px; margin-bottom: 25px; height: 80%; color: #777777; }
.integration-card .logo { font-size: 36px; }
.integration-card h3 {margin: 0 0 15px 0; }
.integration-card p {margin: 0; padding: 0; line-height: 21px;}

.feedback-button {z-index: 1000;}
.feedback-button .ant-float-btn-body { background-color: #F8CB46; box-shadow: none; border: 1px solid #dfae19; }
.feedback-button .ant-float-btn-body svg { color: #bd8e00;  }

.feedback-button:hover .ant-float-btn-body { background-color: #F8CB46; box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.35); border: 1px solid #292311;  }
.feedback-button:hover .ant-float-btn-body svg { color: #292311;  }

.hero-banner { background: #e3f7ff;; padding: 15px; margin: 20px 0; border-radius: 5px; color: #313e43; border: 1px solid #b4c8cf; }
.hero-banner h3 { margin: 20px 0 10px 0; padding: 0; font-size: 30px; font-weight: 500; }
.hero-banner p { font-size: 18px; font-weight: 400; line-height: 30px; }
hero-banner p { font-size: 18px; font-weight: 400; line-height: 30px; }

.page-nav-bar {border-bottom: 1px solid #DDDDDD; background: #FFFFFF;}
.page-primary-nav { border-bottom: 0; margin-bottom: 1px; }
.page-primary-nav.ant-menu .ant-menu-item { background: #FFF !important;  }
.page-primary-nav.ant-menu .ant-menu-item span { padding: 0 20px; }
.page-primary-nav.ant-menu .ant-menu-item:hover,
.page-primary-nav.ant-menu .ant-menu-item-active { background: #FFF !important; font-weight: normal;}
.page-primary-nav.ant-menu .ant-menu-item.ant-menu-item-selected { font-weight: normal; }
.page-primary-nav .warning-icon { display: inline-block; margin: -10px 0 0 2px; padding: 0 !important; vertical-align:middle; color: #b6493f; }

.btn-menu-list { list-style-type: none; margin: 0 0 0 10px; padding: 0; }
.btn-menu-list li { padding-bottom: 15px; }
.btn-menu-list li button { font-size: 16px; text-align: left; display: block; }
.btn-menu-list li button span { text-align: left; }
.btn-menu-list li button svg { font-size: 14px; margin-right: 5px; }

.drawer-col:focus-visible,
.drawer-col:focus,
.drawer-col:active { border: 0 !important; outline: none !important;  border-color: #FFFFFF !important; box-shadow: none !important; }
.project-page-wrapper,
.project-details-page-wrapper { position: relative; height: 100%; }
.project-details-page-wrapper { padding: 20px; }

.page-header-bar { padding: 10px 0 15px 0;}
.project-page-header { margin: 0; padding: 0; font-size: 27px; font-weight: 400}

.stats-blurb { border: 1px solid #EAEAEA; border-radius: 5px; padding: 15px; box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1); height: 105px; }

.stats-blurb .icon { font-size: 41px; text-align: center; vertical-align: middle; display: inline-block; }
.stats-blurb .icon.bug { color: rgb(254, 173, 167); }
.stats-blurb .icon.artefact { color: rgb(122, 197, 255); }
.stats-blurb .icon.risk { color: rgb(255, 179, 149); }
.stats-blurb .icon.env { color: rgb(208, 180, 255); }

.stats-blurb h4 { padding: 0; margin: 0 0 10px 0; }
.stats-blurb p { display: inline-block; width: 50%; padding: 0; margin: 0 0 10px 0; }
.stats-blurb .inline-icon { font-size: 8px; vertical-align: middle; margin-right: 10px; color: #666; }
.stats-blurb .inline-icon.critical,
.issue-severity-critical { color: rgb(196, 45, 35); }
.stats-blurb .inline-icon.high,
.issue-severity-high { color: rgb(239, 135, 8) }
.stats-blurb .inline-icon.medium,
.issue-severity-medium { color: rgb(243, 189, 12); }
.stats-blurb .inline-icon.low,
.issue-severity-low { color: rgb(210, 237, 33); }

.stats-blurb.large { margin: 0 0 20px 0; }
.stats-blurb.large h4 { font-size: 16px; padding: 0; margin: 0 0 10px 0; }
.stats-blurb.large p { width: 100%; }

.issue-details-drawer { background: #fcf8e8 !important; }
tr.issue-row td.issue-type { border-left: 5px solid #FFF;}
tr.highlight-issue-row td.issue-type { border-left: 5px solid #6a9ae2;}
.issue-details-drawer .issue-severity { font-size: 18px; font-weight: 600; text-align: center; }
.issue-details-drawer .issue-severity svg { font-size: 30px; }
.issue-details-drawer .issue-severity spa n { text-align: center; display: block; }
.issue-details-drawer h3,
.issue-details-drawer h4,
.issue-details-drawer p { margin: 0 0 10px 0; padding: 0; }
.issue-details-drawer .ant-tabs-nav-wrap {border-bottom: 1px solid #777 ;}

.checklist-panel { border: 1px solid #DDDDDD; margin: 0 0 25px 0; }
.checklist-panel .ant-collapse-header { font-size: 16px; font-weight: 500;  }
.checklist-panel.isEU_Flag { display: none; }
.compliance_Checklist_hasEU_Flag .checklist-panel.isEU_Flag { display: block; background: #fff5d3; }
.checklist-panel .ant-ribbon-wrapper {position: absolute; margin: -30px 0 0 -40px;}

.error-notification { background: #ffefcb; border: 1px solid #ff5112; border-radius: 5px; }
.error-notification .button-bar { margin-top: 20px; text-align: right; }