.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow {
  /* padding: 10px 0; */
  color: #222222;
  cursor: grab;
}

.dndflow .dndnode .nodeIcon {
  vertical-align: middle;
  display: inline-block;
}

.sidebarGroup {
  border-bottom: 1px solid #CCCCCC;
  border-radius: 0;
  margin-left: -10px;
  margin-right: -10px
}

.sidebarGroup .ant-collapse-header-text {
  font-size: 13px;
  font-weight: 600;
}



.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }
}

.ant-collapse.Critical,
.ant-collapse.Critical .ant-collapse-item {
  border-width: 2px;
  border-color: rgb(201, 5, 8);
}

.ant-collapse.Critical .warning svg {
  color: rgb(201, 5, 8);
}

.ant-collapse.High,
.ant-collapse.High .ant-collapse-item {
  border-width: 2px;
  border-color: rgb(255, 119, 0);
}

.ant-collapse.High .warning svg {
  color: rgb(255, 119, 0);
}

.ant-collapse.Medium,
.ant-collapse.Medium .ant-collapse-item {
  border-width: 2px;
  border-color: rgb(255, 225, 0);
}

.ant-collapse.Medium .warning svg {
  color: rgb(255, 225, 0);
}

.canvasNode {
  background: #FFFFFF;
  color: #444444;
  border: 1px solid #CCCCCC;
  padding: 4px 16px 4px 8px;
  border-radius: 8px;
  text-align: center;
}

.canvasNode:hover { box-shadow: 1px 1px 5px rgba(122, 122, 122, 0.6); }

.react-flow__node.selected .canvasNode { border-color: #777777; box-shadow: 1px 1px 5px rgba(122, 122, 122, 0.6); }

.react-flow__handle { background: transparent !important; border: 0 !important;  }
.react-flow__node.selected .react-flow__handle,
.react-flow__node:hover .react-flow__handle { background: #777777 !important; }

.canvasNode svg {
  font-size: .8rem;
  display: inline-block; vertical-align: middle;
  margin-right: 5px;
}

.canvasNode .nodeLabel {
  font-weight: 500;
  font-size: .5rem;
  margin: 5px 0;
  line-height: 1.4em;
  display: inline-block;
}

.canvasNode .node-icon-text { padding-top: 8px; font-weight: bolder; font-size: .4rem; margin-right: 5px; background: #EAEAEA; width: 25px; height: 25px; border-radius: 100px; vertical-align: middle; text-align: center; display: inline-block; }

.react-flow__node {
  font-size: .6rem;
}

.canvasNode p {
  font-size: .6rem;
}

.canvasNode :global .react-flow__resize-control.handle {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.customStyle {
  width: 100%;
  height: 100%;
}

.wrapper {
  flex-direction: row;
  display: flex;
  height: 100%;
}

.wrapper aside {
  border-right: 1px solid #333;
  padding: 15px;
  font-size: 12px;
  background-color: #222;
  color: #fff;
}

.wrapper :global aside div[class^='react-flow__node-'] {
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  width: 40px;
  height: 40px;
  position: relative;
  color: #fff;
  cursor: grab;
  margin-bottom: 35px;
}

.wrapper .rfWrapper {
  flex-grow: 1;
  height: 100%;
  /* background: #fcfcfc; */
}

.wrapper :global .react-flow__handle {
  width: 6px;
  height: 6px;
  background: #EAEAEA;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.5);
}

.wrapper :global .react-flow__node-node {
  background: rgba(180, 75, 159, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.1);
  border: 1px solid #eee;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 2px;
}

.wrapper :global .label {
  position: absolute;
  bottom: 0;
  width: 100px;
  left: 50%;
  transform: translate(-50%, 120%);
  color: #f5f5f5;
  font-size: 12px;
}

.wrapper :global .icon {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color: #f5f5f5;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper :global .react-flow__node-node.selected,
.wrapper :global .react-flow__node-node:hover {
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.2);
}

/* .wrapper :global .react-flow__node-node.selected {
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.8);
} */

.wrapper :global .react-flow {
  background: #222;
}

.nodeToolBar { padding: 5px; background: #FFFFFF; border: 1px solid #DDDDDD; border-radius: 5px; box-shadow: 0 2px 5px rgba(0, 0, 4, 0.2); }
.nodeToolBar .ant-btn { margin-right: 5px; border: 0; box-shadow: none; }

.userTags { padding: 15px 0; }
.userTags span.ant-tag { font-size: 0.92rem; padding: 5px 10px; }

.tagsWrapper { padding: 15px 0 0 0; }
.tagsWrapper span.ant-tag { font-size: 0.92rem; padding: 5px 10px; margin-bottom: 10px; }


.canvasPopover { font-size: 14px; width: 400px; }
.canvasPopover .buttonWrapper { text-align: right; padding-top: 10px; }
.canvasPopover p { font-size: 13px; margin: 0; }
.canvasPopover .comments .ant-list-item { padding: 15px 0; }
.canvasPopover .comments .ant-list-item .ant-list-item-meta h4.ant-list-item-meta-title { margin: 0; margin-block-end: 0; margin-bottom: 0; font-size: 13px;}
.canvasPopover .comments .ant-list-item .ant-list-item-meta { margin-bottom: 5px; }

.canvasNode .nodeInput { font-size: .5rem; padding: 1px 3px; border-radius: 2px; display: inline-block; width: 80%;}
.canvasEdge {pointer-events: all; position: absolute; background: #efefef ; padding: 2px 8px; font-size: .5rem; font-weight: 500; border: 1px solid #DDDDDD; border-radius: 5px;}
.canvasEdge .edgeInput { position: absolute; top: -5px; left: -15px; width: 80px; font-size: .5rem; padding: 2px 5px; border-radius: 2px;}

.group-node-label { position: absolute; font-size: .5rem; font-weight: 500; }
.group-node-label svg { vertical-align: middle; margin-right: 3px; }
.group-node-label input.nodeInput { font-size: .5rem; padding: 1px 3px; border-radius: 2px; }
.react-flow__node-group { border-width: 2px !important; border-style: dashed !important;  border-color: #BBBBBB !important; background-color: transparent !important; }
.react-flow__node-group .line { border-color: transparent !important; }
.react-flow__node-group.group-node-INTERNET { border-color: rgb(225, 98, 52) !important;  }
.react-flow__node-group.group-node-INTRANET { border-color: rgb(42, 215, 160) !important;  }
.react-flow__node-group.group-node-PRIVATE_NETWORK { border-color: rgb(149, 42, 215) !important;  }
.react-flow__node-group.group-node-SOFTWARE_ISOLATION { border-color: rgb(215, 42, 189) !important;  }
.react-flow__node-group:hover { box-shadow: 1px 1px 5px rgba(122, 122, 122, 0.6) !important; }

.canvasNode.project-node { border-color: #0ac100;  }
.canvasNode .node-status { position: absolute; color: #0ac100; margin: -10px 2px 2px -10px; background: #FFF; }
.canvasNode .node-status svg { margin: 0;  font-size: .7rem;}
.canvasNode .node-status.error-state { color: #c73030;}
.canvasNode.project-node.error-state { border-color: #c73030;  }

.empty-state { margin-top: 100px; }
.empty-state .ant-empty-description {color: #A7A7A7;}
.threat-panel .threat-title { font-weight: 500; font-size: 15px; }
.threat-panel .ant-collapse-header { padding-right: 0 !important; }
.threat-risk-tag {border-radius: 25px; width: 30px; height: 30px; text-align: center; font-weight: 600; border-width: 2px; }

.threat-card { border: 1px solid #EAEAEA; border-radius: 8px; margin-bottom: 25px; }
.threat-card .message { transition: opacity 2s ease-in-out; }
.threat-card .message.fade-out { opacity: 0; }
/* .threat-card.High { border-color: rgb(229, 127, 127);}
.threat-card.Medium { border-color: rgb(255, 187, 128);}
.threat-card.Low { border-color: rgb(255, 225, 0);} */

.threat-card label { font-size: 13px; color: #777777; }


.threat-card .threat-card-header { padding: 15px 10px;}
.threat-card .threat-card-header .threat-title { font-size: 14px; font-weight: 500; }
.threat-card .threat-card-info { border-bottom: 1px solid #EAEAEA; border-top: 1px solid #EAEAEA; padding: 10px 0; }
.threat-card .threat-card-info .blob { padding: 5px 10px; text-align: left; min-height: 45px; }
.threat-card .threat-card-info .blob.divider { border-right: 1px solid #EAEAEA; }
.threat-card .threat-card-info .blob h6 { margin: 0; padding: 0; font-size: 10px; font-weight: 400; color: #888888; }
.threat-card .threat-card-info .blob p { margin: 4px 0 0 0; font-size: 12px; font-weight: 500; line-height: normal; }
.threat-card .threat-card-info .ant-tag { font-size: 12px; padding: 3px; }

.threat-card .ant-collapse-content.ant-collapse-content-active { border-top: 1px solid #EAEAEA; }

/* .threat-card .threat-card-details { padding: 20px 10px;} */
.threat-card .threat-card-details .ant-collapse { border: 0; border-radius: 0; }
.react-flow__edgelabel-renderer .ant-btn-circle { min-width: 0.1rem;
  width: 0.9rem;
  height: 0.1rem;
  padding: 0px;  }
.react-flow__edgelabel-renderer .anticon-form {width: 0.5rem;}