
.project-sider{
background-color: black;
width:566px;
}

.menu-title{
    color: white;
}

.ant-menu-item-selected,
.ant-menu-item-active { background-color:#3a3a3a  !important; font-weight: 700; border-radius: 5px !important; }

.main-nav-trigger {
    font-size: 21px;
    font-weight: bold;
    width:40px;
    height:40px;
}

.menu-org-name {
    position: absolute;
    padding: 15px 0 20px 0;
    bottom: 0;
    left: 0;
    color: #FFFFFF;
    width: -webkit-fill-available;
}

.menu-org-name button   {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
}

.menu-org-name button.ant-btn-link:hover {
    color: rgb(255, 230, 0) !important;
}

.bodyContent {
    padding: 10px 25px;
    margin-bottom: 20px;
    min-height: 360px;
    background: #FFF;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
}

.add-on-select { width: 200px;}
 .font-small { font-size: 11px; }
 .border-spacer { display: block; border-bottom: 1px solid #EAEAEA; height: 1px; margin: 25px 0; }

 .threatBlurb p { margin: 0; line-height: 26px; }
 .threatBlurb label { display: block; font-weight: 700; margin-bottom: 3px; }

 .project-status-blurb { padding: 10px; border: 1px dashed #b6b4b4; border-radius: 5px; height: 100%; }
 .project-status-blurb.selectable:hover { border-color: #1677ff; color: #1677ff; cursor: pointer; }
 .project-status-blurb.active-status { background-color: #f1f1f1; border-color: #d9d9d9; color: #777777; }
 .project-status-blurb .status-icon { display: none; position: absolute; margin: -20px; background: #FFF; border-radius: 100%; font-size: 19px; color:rgb(1, 170, 1); }
 .project-status-blurb.active-status .status-icon { display: block; }

 .project-type-blob { display: inline-block; border-radius: 5px; width: 220px;border: 1px solid rgb(223, 223, 223); text-align: left; vertical-align: middle; padding: 5px; margin: 10px 15px 10px 0; font-size: 13px; font-weight: 500; }
 .project-type-blob h2 { font-size: 21px; margin: 0 10px 0 0; display: inline-block; vertical-align: middle; }
 .project-type-blob:hover,
 .project-type-blob.selected {border-color: #1677ff; color: #1677ff; cursor: pointer;}

 .icon-option-field { border-radius: 5px; border: 1px solid rgb(217,217,217);  padding: 10px; margin: 10px 0 10px 0; font-size: 13px; font-weight: 400; }
 .icon-option-field.option-inline { display: inline-block; margin-right: 15px; }
 .icon-option-field span { font-size: 21px; margin: 0 10px 0 0; display: inline-block; vertical-align: middle; }
 .icon-option-field label { display: inline-block !important; font-weight: 600 !important; }
 .icon-option-field p { padding: 0 0 0 5px; margin: 0; display: inline-block; }
 .icon-option-field:hover,
 .icon-option-field.selected {border-color: #1677ff; color: #1677ff; cursor: pointer;}


 .site-left-nav .ant-menu-item { font-size: 16px; margin-bottom: 20px; }